import React from 'react';
import {connect} from "react-redux";
import {DOWNLOAD_REPORT, generatePdf} from "../api";
import {setFreePdfGenerated} from "../actions";
import {Container,Row,Col,Spinner} from 'react-bootstrap';


class DownloadFreeReport extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        generatePdf(this.props.reportId).then((data) => {
            if(data.data.status){
                this.props.setFreePdfGenerated(true)
            }else{
                alert("pdf generation error");
            }
        })
    }

    render() {
        return (
            <div>
                {!this.props.lotReportType &&
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={() => this.props.hideFreeDownload()}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>
                }
                <h4 className={"mt-3 mb-3"}>Thank you for taking the CQ-i.</h4>
                {!this.props.freePdfGenerated ?
                    <>
                        <p>Generating your report, please wait..</p>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </>
                    :
                    <>
                        <p style={{marginBottom:"10px"}}>Please download the CQ-i Descriptive Summary, using the link below.</p>
                        <p>We've also sent you an e-mail with the download link to the e-mail address you provided.</p>
                        <p>Enjoy the journey!</p>
                        <a target={"_blank"} className={"button mt-3"} href={DOWNLOAD_REPORT+this.props.reportId+"/"+this.props.reportCode}>Download the CQ-i Descriptive Summary</a>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        freePdfGenerated : state.global.freePdfGenerated,
        lotReportType : state.global.lotReportType,
        reportId : state.global.reportId,
        reportCode : state.global.reportCode,
        reportType : state.global.reportType
    }
}

const mapDispatchToProps = () => {
    return {
        setFreePdfGenerated
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(DownloadFreeReport);