import React from 'react';
import {goto,setUserData,setReportId,setReportCode} from "../actions";
import {saveReport} from "../api";
import CustomizeSingle from './CustomizeSingle'
import CustomizeResearch from './CustomizeResearch'
import {connect} from 'react-redux'
import {Container,Row,Col} from 'react-bootstrap'

class CustomizeStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorText: ""
        }
    }

    componentDidMount(){
        setTimeout(() => {
            window.scrollTo(0, document.getElementById("page-title").offsetTop-300)
        },100)

        // saveReport(this.props.state).then((data) => {
        //     this.props.setReportId(data.data.reportId)
        //     this.props.setReportCode(data.data.reportCode)
        // })
    }

    disabled(){
        if(this.props.global.itemsType){
            if(this.props.global.itemsType == "custom"){
                if(this.props.global.customFactors && this.props.global.customFactors.length){
                    return false;
                }
            }else{
                return false;
            }
        }
        return true;
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.props.goto('step1')}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>

                <CustomizeSingle/>

                <Row className={"mb-5 mt-4"}>
                    <Col>
                        {this.state.error && <h4>{this.state.errorText}</h4>}
                        <button disabled={this.disabled()} className={"action-button"} onClick={() => this.props.goto('demographicsStep')}>Next step <i className={"ml-3 icon-arrow-right"}></i></button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        step : state.step,
        userData: state.userData,
        state: state,
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setUserData,
        setReportId,
        setReportCode
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(CustomizeStep);