import React from 'react';
import IntroText from './IntroText'
import CheckResearch from './CheckResearch'
import CustomizeResearch from './CustomizeResearch'
import {connect} from "react-redux";
import {
    goto,
    setRetestEmail,
    setUserEmail,
    resetState,
    setResearchCode,
    setResearchName,
    setReportId,
    setReportCode,
    setAllUserData,
    setReportType,
    setLotReportType,
    setItemsType,
    setCustomFactors,
    addCustomFactor,
    setResearchAdminEmail,
    setResearchAdminName,
    setResearchInviteText
} from "../actions";

import {Container,Row,Col,Spinner} from 'react-bootstrap';
import {checkRetest,downloadLinks,checkResearchCode} from '../api.js'
import PreviousReportItem from './elements/PreviousReportItem'
import {confirmIntent} from "../api";

class FormStep1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            retestCode: retestCode,
            codeLoader: false,
            allButtons: true,
            codeError: "",
            checkCode: researchCode,
            previousReports: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkRetestCode = this.checkRetestCode.bind(this);
        this.startRetest = this.startRetest.bind(this);
        this.checkResearchCode = this.checkResearchCode.bind(this);

    }

    componentDidMount(){
        if(this.state.checkCode){
            this.checkResearchCode(researchCode);
        }

        if(this.state.retestCode){
            this.checkRetestCode(retestCode);
        }

        this.props.setReportType("full");
        this.props.setItemsType("full");
    }

    startRetest(){
        this.props.setReportCode(this.state.retestCode)
        this.props.setAllUserData(this.state.previousReports[0]['demografice'])
        this.props.goto("customizeStep")
    }

    checkRetestCode(){

        if(this.state.retestCode.length == 0){
            this.setState({
                codeError: "Please enter the previous test ID"
            })
            return;
        }

        this.setState({
            codeLoader: true,
            codeError: ""
        })

        checkRetest(this.state.retestCode).then((response) => {

            this.setState({
                codeLoader: false
            })
            if(response.data.status){
                this.setState({
                    'previousReports': response.data.reports
                })
            }else{
                this.setState({
                    codeError: response.data.message
                })
            }
        });

    }

    handleInputChange(event) {
        this.setState({
            codeError:""
        })
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
    }

    checkResearchCode(researchCode){
        checkResearchCode(researchCode).then((data) => {
            this.setState({
                checkCode: false,
            })
            
            // console.log("data",data);

            if(data.data.status){
                this.props.setResearchCode(data.data.researchCode);
                this.props.setResearchName(data.data.researchName);
                this.props.setLotReportType(data.data.lotReportType);
                this.props.setReportType(data.data.lotReportType);
                this.props.setItemsType(data.data.itemsType);
                this.props.setCustomFactors(data.data.factors);
                this.props.setResearchAdminEmail(data.data.researchAdminEmail);
                this.props.setResearchAdminName(data.data.researchAdminName);
                this.props.setResearchInviteText(data.data.inviteText);

                this.props.setUserEmail(data.data.email);
                if(data.data.reportId){
                    if(this.props.global.reportId && this.props.global.reportId != data.data.reportId){
                        unifiyReport(this.props.global.reportId,data.data.reportId).then((data) => {
                            // maybe show errors if this goes wrong
                        })
                    }else{
                        this.props.setReportId(data.data.reportId);
                    }
                }
            }
        })
    }

    render() {
        let canRetest = false;
        for(let i = 0; i < this.state.previousReports.length; i++){
            if(this.state.previousReports[i].status == "finished"){
                canRetest = true;
            }
        }
        return (
            this.props.global.researchCode ?
                <CustomizeResearch/> :
            <>
                <Row>
                    <Col md={8}>
                        <IntroText/>
                    </Col>
                </Row>
                <div className={"hr"}></div>

                <CheckResearch/>

                <Row className={"two-buttons-sections mt-2"}>
                    <Col md={5} xs={12}>
                        <label htmlFor="retestCode">Previous test ID</label>
                        <input placeholder={"(optional)"} className={this.state.codeError.length > 0 ? "error" : ""} id={"retestCode"} name="retestCode" value={this.state.retestCode} onChange={this.handleInputChange} />
                        {this.state.codeLoader &&
                        <Spinner className={"ml-5"} animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        }
                    </Col>
                    {this.state.retestCode && !this.state.codeLoader &&
                    <>
                        <Col className={"mt-4 mt-md-0 text-center"} md={3} xs={12}>
                            <button className={"action-button secondary longer"} onClick={() => this.checkRetestCode()}>Check code</button>
                        </Col>
                    </>
                    }
                </Row>
                <Row>
                    <Col>
                        <div className={"code-error"}>{this.state.codeError}</div>
                        <span className={"small-info"}>* Your Test ID is printed on the cover of the Descriptive Summary. </span>
                    </Col>
                </Row>
                {this.state.previousReports &&
                <>
                    <Row>
                        <Col>
                            <ul className={"mt-3"}>
                                {this.state.previousReports.map((report) => <PreviousReportItem key={report.id} report={report} />)}
                            </ul>
                        </Col>
                    </Row>
                </>
                }
                <div className={"hr"}></div>
                <Row className={""}>

                    {!this.state.checkCode &&
                    <>
                        {!canRetest ?
                        <Col>
                            <button className={"action-button"} onClick={() => this.props.goto('demographicsStep')}>
                                Next step <i className={"ml-3 icon-arrow-right"}></i>
                            </button>
                            {/*<button className={"action-button"} onClick={() => this.props.resetState()}>Reset</button>*/}
                        </Col>
                        :
                        <Col>
                            <button className={"action-button auto"} onClick={() => this.startRetest()}>Begin a new assessment <i className={"ml-3 icon-arrow-right"}></i></button>
                        </Col>
                        }

                    </>
                    }
                    {this.state.checkCode &&
                    <>
                        <h5 className={"mr-4"}>Checking your research code..</h5>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </>
                    }
                </Row>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        step : state.step,
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setRetestEmail,
        setUserEmail,
        resetState,
        setResearchCode,
        setResearchName,
        setReportId,
        setReportCode,
        setAllUserData,
        setReportType,
        setLotReportType,
        setItemsType,
        setCustomFactors,
        addCustomFactor,
        setResearchAdminEmail,
        setResearchAdminName,
        setResearchInviteText
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(FormStep1);