import React from 'react';

class IntroText extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            <div className="intro-text" dangerouslySetInnerHTML={{ __html : texts.welcomeText }}>
            </div>
            </>
        );
    }
}

export default IntroText