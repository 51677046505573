export const goto = (step) => {
    return {
        type: 'goto',
        payload: step
    }
}

export const setRetestEmail = (email) => {
    return {
        type: 'retestEmail',
        payload: email
    }
}

export const setUserEmail = (email) => {
    return {
        type: 'userEmail',
        payload: email
    }
}

export const setUserData = (field,data) => {
    return {
        type: 'userData',
        payload: {
            field: field,
            data: data
        }
    }
}

export const setAnswer = (item,answer) => {
    return {
        type: 'answer',
        payload: {
            item: item,
            answer: answer
        }
    }
}

export const setAllAnswers = (answers) => {
    return {
        type: 'allAnswers',
        payload: answers
    }
}

export const setAllUserData = (data) => {
    return {
        type: 'allUserData',
        payload: data
    }
}

export const setResearchCode = (code) => ({
    type: "researchCode",
    payload: code ,
});

export const setResearchName = (name) => ({
    type: "researchName",
    payload: name ,
});

export const setResearchAdminName = (name) => ({
    type: "researchAdminName",
    payload: name ,
});

export const setResearchAdminEmail = (email) => ({
    type: "researchAdminEmail",
    payload: email ,
});

export const setResearchInviteText = (text) => ({
    type: "researchInviteText",
    payload: text ,
});

export const setLotReportType = (type) => ({
    type: "lotReportType",
    payload: type ,
});

export const setReportId = (id) => ({
    type: "reportId",
    payload: id ,
});

export const setReportStatus = (status) => ({
    type: "reportStatus",
    payload: status ,
});

export const setReportCode = (code) => ({
    type: "reportCode",
    payload: code ,
});

export const setReportType = (type) => ({
    type: "reportType",
    payload: type ,
});

export const setItemsType = (type) => ({
    type: "itemsType",
    payload: type ,
});

export const setCustomFactors = (factors) => ({
    type: "setCustomFactors",
    payload: factors ,
});

export const addCustomFactor = (factorId) => ({
    type: "addCustomFactor",
    payload: factorId ,
});

export const removeCustomFactor = (factorId) => ({
    type: "removeCustomFactor",
    payload: factorId ,
});

export const setGiftCode = (type) => ({
    type: "giftCode",
    payload: type ,
});

export const setFullPdfGenerated = (value) => ({
    type: "fullPdfGenerated",
    payload: value ,
});

export const setFreePdfGenerated = (value) => ({
    type: "freePdfGenerated",
    payload: value ,
});

export const setGiftPdfGenerated = (value) => ({
    type: "giftPdfGenerated",
    payload: value ,
});

export const setBookmarkSaved = (value) => ({
    type: "bookmarkSaved",
    payload: value ,
});

export const setPrivacyAccepted = (value) => ({
    type: "privacyAccepted",
    payload: value ,
});

export const resetState = () => {
    return {
        type: 'CLEAR'
    }
}
