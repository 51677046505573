import { combineReducers } from 'redux'

export const userDataReducer = (state = {}, action) => {
    switch (action.type){
        case 'userEmail':
            return { ...state, email:action.payload }
        case 'userData':
            return { ...state, [action.payload.field]:action.payload.data }
        case 'allUserData':
            return action.payload
        default:
            if(Object.keys(state).length === 0){
                return {
                    name:"",
                    email:"",
                    age:"",
                    gender:"",
                    country:"",
                    education:"",
                    income:""
                }
            }
            return state;
    }
}

const wizzardReducer = (state = "step1", action) => {
    switch (action.type){
        case 'goto':
            return action.payload;
        default:
            return state;
    }
}

const globalReducer = (state = {}, action) => {
    switch (action.type){
        case 'retestEmail':
            return { ...state, retestEmail:action.payload }
        case 'researchCode':
            return { ...state, researchCode:action.payload }
        case 'researchName':
            return { ...state, researchName:action.payload }
        case 'researchAdminName':
            return { ...state, researchAdminName:action.payload }
        case 'researchAdminEmail':
            return { ...state, researchAdminEmail:action.payload }
        case 'researchInviteText':
            return { ...state, researchInviteText:action.payload }
        case 'lotReportType':
            return { ...state, lotReportType:action.payload }
        case 'reportType':
            return { ...state, reportType:action.payload }
        case 'itemsType':
            return { ...state, itemsType:action.payload }
        case 'giftCode':
            return { ...state, giftCode:action.payload }
        case 'reportId':
            return { ...state, reportId:action.payload }
        case 'reportStatus':
            return { ...state, reportStatus:action.payload }
        case 'reportCode':
            return { ...state, reportCode:action.payload }
        case 'fullPdfGenerated':
            return { ...state, fullPdfGenerated:action.payload }
        case 'freePdfGenerated':
            return { ...state, freePdfGenerated:action.payload }
        case 'giftPdfGenerated':
            return { ...state, giftPdfGenerated:action.payload }
        case 'bookmarkSaved':
            return { ...state, bookmarkSaved:action.payload }
        case 'setCustomFactors':
            return { ...state, customFactors:action.payload }
        case 'privacyAccepted':
            return { ...state, privacyAccepted:action.payload }
        case 'addCustomFactor':

            if(typeof state.customFactors != "undefined"){
                var factors = state.customFactors;
            }else{
                var factors = [];
            }

            factors.push(parseInt(action.payload));
            factors = factors.filter((value, index, self) => self.indexOf(value) === index  );

            return { ...state, customFactors:factors }

        case 'removeCustomFactor':

            if(typeof state.customFactors != "undefined"){
                var factors = state.customFactors;
            }else{
                var factors = [];
            }

            var index = factors.indexOf(parseInt(action.payload));
            if (index !== -1) {
                factors.splice(index, 1);
            }

            return { ...state, customFactors:factors }

        default:
            return state;
    }
}

const answersReducer = (state = {}, action) => {
    switch (action.type){
        case 'answer':
            return { ...state, [action.payload.item]:action.payload.answer };
        case 'allAnswers':
            return action.payload;
        default:
            if(Object.keys(state).length === 0){
                let answers = {};
                for(let i=1; i<=items.length;i++){
                    answers[i] = 0;
                }
                return answers;
            }
            return state;
    }
}

const allReducers = combineReducers({
    step: wizzardReducer,
    userData: userDataReducer,
    global: globalReducer,
    answers: answersReducer
})

const rootReducer = (state, action) => {
    if (action.type === "CLEAR") {
        state = {"step" : "step1"};
    }
    return allReducers(state, action);
};

export default rootReducer