import React from 'react';
import {goto,setAnswer,setBookmarkSaved,setReportId,setReportCode} from "../actions";
import {connect} from 'react-redux'
import {Container,Row,Col, Spinner} from 'react-bootstrap'
import FormItem from './FormItem'
import {scrollTo} from '../utils'
import {saveReport} from "../api";

class FormQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 40,
            page: 1,
            sticky:false,
            bookmarkLoader: false,
            bookmarkSaved: false,
            bookmarkPopOpen: false,
        }
        this.perPage = 60;

        this.stickyRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this);
        this.demoFill = this.demoFill.bind(this);
        this.saveBookmark = this.saveBookmark.bind(this);
    }

    handleScroll() {
        if(window.scrollY > this.stickyRef.current.offsetTop-72){
            if(!this.state.sticky){
                this.setState({sticky:true})
            }
        }else{
            if(this.state.sticky){
                this.setState({sticky: false})
            }
        }
    }

    componentDidMount(){
        document.getElementById("questionsHeaderWrap").style.height  = document.getElementById("questionsHeaderWrap").offsetHeight + "px";
        window.addEventListener("scroll", this.handleScroll)

        //check the current page in case of continuation
        if(this.checkContinueAssesment()){
            setTimeout(() => {
                window.scrollTo(0, document.getElementById("questionsHeaderWrap").offsetTop - 400)
            },100)
        }
        
        saveReport(this.props.state).then((data) => {
            this.props.setReportId(data.data.reportId)
            this.props.setReportCode(data.data.reportCode)
        })

    }
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }

    checkContinueAssesment(){
        let unanswered = null;
        for(let i in this.props.answers){
            if(this.props.answers[i] == 0){
                unanswered = i;
                break;
            }
        }

        let continuePage = Math.floor(unanswered / this.perPage) + 1;
        if(continuePage > 1){
            this.setState({page: continuePage},function(){
                this.validateAnswersOnPage();
            })
            return false;
        }else if(unanswered > 1){
            this.validateAnswersOnPage();
            return false;
        }else{
            return true;
        }

    }

    validateAnswersOnPage(){
        if (document.querySelectorAll(".unanswered").length != 0) {
            scrollTo(document.querySelectorAll(".unanswered")[0].offsetTop - 300, () => {
                document.querySelectorAll(".unanswered")[0].classList.add("highlight")
                setTimeout(function () {
                    document.querySelector(".item-row.highlight").classList.remove("highlight");
                }, 2000);
            });
            return false;
        }
        return true;
    }

    turnpage(val) {
        if(this.validateAnswersOnPage()){
            this.setState({page: this.state.page + val})
            setTimeout(() => {
                if(document.querySelectorAll(".unanswered").length > 0){
                    window.scrollTo(0, document.querySelectorAll(".unanswered")[0].offsetTop - 300)
                }
            },100)
        }
        
    }

    finishTest(){
        if(this.validateAnswersOnPage()){
            this.props.goto("stepFinal");
        }
    }

    openBookmarkPop(e){
        e.preventDefault();
        this.setState({bookmarkPopOpen:true})
    }

    closeBookmarkPop(e){
        e.preventDefault();
        this.setState({bookmarkPopOpen:false})
    }

    saveBookmark(e){
        e.preventDefault();
        this.setState({bookmarkLoader:true})
        saveReport(this.props.state,true).then(() => {
            this.setState({
                bookmarkLoader:false
            })
            this.props.setBookmarkSaved(true);
        })
    }

    demoFill(){
        console.log("fill");
        const customItems = this.getItems();
        customItems.forEach(item => {
            this.props.setAnswer(item.nr,parseInt(Math.floor(Math.random() * Math.floor(6)))+1)
        })
    }
    getItems(){
        if(this.props.global.itemsType == "custom"){
            return items.filter((item) =>
                item.factors.filter(factorId => this.props.global.customFactors.includes(factorId)).length > 0
            )
        }else{
            return items;
        }

    }

    render() {
        let answered = 0;
        for(let i in this.props.answers){
            if(this.props.answers[i] != 0){
                answered++;  
            }
        }

        return (
            <>
                {!this.props.bookmarkSaved &&
                <>
                    <Row>
                        <Col>
                            <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.props.goto('instructionsStep')}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                            <button className={"float-right"} onClick={this.demoFill}>Demo fill</button>
                            <button onClick={() => this.props.goto('stepFinal')}>Complete</button>
                        </Col>
                    </Row>

                    <div id={"questionsHeaderWrap"} ref={this.stickyRef}>
                        <div className={"questionsHeader" + (this.state.sticky ? " sticky container" : "")}>
                            <Row>
                                <Col>
                                    <div className={"meter d-block"}>
                                        <span className={"progr"}
                                            style={{width: (answered / this.getItems().length * 100) + '%'}}></span>
                                        <div className="page-meter">
                                            page {this.state.page} / {Math.ceil(this.getItems().length / this.perPage)} &nbsp;
                                        </div>
                                        <div className="text-meter">
                                            <span className="val">{Math.round((answered / this.getItems().length * 100))}</span>%
                                            completed
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/*<Row className={""}>*/}
                                {/*<Col>*/}
                                    {/*<Row>*/}
                                        {/*<Col className={"answerLabel"}>Almost always<span>(definitely yes)</span></Col>*/}
                                        {/*<Col className={"answerLabel"}>Very frequently<span>Usually</span></Col>*/}
                                        {/*<Col className={"answerLabel"}>Quite often<span>Sometimes</span></Col>*/}
                                        {/*<Col className={"answerLabel"}>Occasionally<span>Seldom</span></Col>*/}
                                        {/*<Col className={"answerLabel"}>Very rarely<span>Once in a while</span></Col>*/}
                                        {/*<Col className={"answerLabel"}>Almost never <span>(definitely no)</span></Col>*/}
                                    {/*</Row>*/}
                                {/*</Col>*/}
                            {/*</Row>*/}
                        </div>
                    </div>
                    <div className={"questionsHolder"}>
                        {this.getItems().map((item,k) => {
                            return (k+1) <= (this.state.page * this.perPage) && (k+1) > ((this.state.page-1) * this.perPage) ? <FormItem key={k} number={k} answer={typeof this.props.answers[item.nr] != "undefined" ? this.props.answers[item.nr] : 0 } item={item}/>: ""
                        })}
                    </div>
                    <Row>
                        <Col className={"justify-content-center pagination"}>
                            {this.state.page != 1 &&
                            <button className={"action-button mr-0 mr-md-2"}
                                    onClick={() => this.turnpage(-1)}><i
                                className={"icon-arrow-left mr-3"}></i> Back</button>
                            }
                            {(this.state.page * this.perPage) < this.getItems().length &&
                            <button className={"action-button ml-0 ml-md-2 mt-2 mt-sm-0"} onClick={() => this.turnpage(1)}>Next <i
                                className={"icon-arrow-right ml-3"}></i></button>
                            }
                            {(this.state.page * this.perPage) >= this.getItems().length &&
                            <button className={"action-button ml-0 ml-md-2 mt-2 mt-sm-0"} onClick={() => this.finishTest() }>Finish <i
                                className={"icon-arrow-right ml-3"}></i></button>
                            }
                        </Col>
                    </Row>
                </>}
                {this.props.bookmarkSaved ?
                    <Row>
                        <Col>
                            <p className={"app-msg"}>Your data is saved for 3 months!<br/>You can now continue the assessment from another browser or device using the link in the email we've just sent to the address you provided.<br/>Alternatively you can use this test ID: <strong>{this.props.state.global.reportCode}</strong>, on the first page of the assessment.</p>
                        </Col>
                    </Row>
                    :
                    <Row>
                       
                        {this.state.bookmarkLoader ?
                            <Col className={"text-center"}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </Col>
                            :
                            <>
                            {this.state.bookmarkPopOpen ?
                                <Col className={"text-center"}>
                                    <h5>Pausing the assessment will terminate the current session. You will be able to continue your assessment from any browser and device using the code or link we are going to send via email.</h5>
                                    <a className={"btn btn-info mt-4"} href={"#"} onClick={(e) => this.saveBookmark(e)}><i className={"mr-2 icon-ribbon"}></i>Save answers for later</a>
                                    <a className={"border-dark d-block mt-3"} href={"#"} onClick={(e) => this.closeBookmarkPop(e)}>Cancel saving operation and continue the assement now</a>
                                </Col>
                                :
                                <Col className={"text-right"}>
                                    <a className={"border-bottom border-dark"} href={"#"} onClick={(e) => this.openBookmarkPop(e)}><i className={"mr-2 icon-ribbon"}></i>Save answers and pause assessment</a>
                                </Col>
                            }
                            </>
                        }
                    </Row>
                 }
                
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        step : state.step,
        answers : state.answers,
        state: state,
        global: state.global,
        bookmarkSaved: state.global.bookmarkSaved
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setAnswer,
        setBookmarkSaved,
        setReportId,
        setReportCode
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(FormQuestions);