import React from 'react';
import {connect} from "react-redux";
import {generatePdf,DOWNLOAD_REPORT} from "../api";
import {setFullPdfGenerated} from "../actions";
import {Container,Row,Col,Spinner} from 'react-bootstrap';


class DownloadFullReport extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        generatePdf(this.props.reportId).then((data) => {
            if(data.data.status){
                this.props.setFullPdfGenerated(true)
            }else{
                alert("pdf generation error");
            }
        })
    }

    render() {
        return (
            <div>
                {!this.props.lotReportType ?
                    <h4 className={"mt-3 mb-3"}>Your payment was successful.<br/> Thank you for your licensing payment.</h4>
                    :
                    <h4 className={"mt-3 mb-3"}>Thank you for taking the CQ-i.</h4>
                }
                {!this.props.fullPdfGenerated ?
                    <>
                        <p>Generating your report, please wait..</p>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </>
                    :
                    <>
                        <p style={{marginBottom:"10px",marginTop:"10px"}}>Please download the CQ-i Assessment Report, using the link below</p>
                        { this.props.reportType == "full" ?
                            <p style={{marginBottom: "10px"}}>We've also sent you an e-mail with the download link to
                                the e-mail address you provided.<br/>If you want to invite a friend to take the test,
                                the e-mail includes a gift code that will allow them to download the report at no cost.
                            </p>
                            :
                            <p>We've also sent you an e-mail with the download link to the e-mail address you provided.</p>
                        }
                        <p>Enjoy the journey!</p>
                        <a target={"_blank"} className={"button mt-3"} href={DOWNLOAD_REPORT+this.props.reportId+"/"+this.props.reportCode}>Download the CQ-i Assessment Report</a>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fullPdfGenerated : state.global.fullPdfGenerated,
        lotReportType : state.global.lotReportType,
        reportId : state.global.reportId,
        reportCode : state.global.reportCode,
        reportType : state.global.reportType
    }
}

const mapDispatchToProps = () => {
    return {
        setFullPdfGenerated
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(DownloadFullReport);