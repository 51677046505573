import React from 'react';
import {connect} from "react-redux";
import {Row,Col,Spinner} from 'react-bootstrap'
import {
    goto,
    resetState,
    setReportId,
    setResearchCode,
    setReportType,
    setResearchName,
    setRetestEmail,
    setUserEmail,
    setLotReportType,
    setItemsType,
    setCustomFactors,
    addCustomFactor,
    setResearchAdminEmail,
    setResearchAdminName,
    setResearchInviteText,
} from "../actions";
import {checkResearchCode,unifiyReport} from "../api";

class CheckResearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openCodeForChecking: false,
            codeForChecking: "",
            codeForCheckingLoader: false,
            codeForCheckingError: ""
        }

        this.handleCodeForCheckingChange = this.handleCodeForCheckingChange.bind(this);
        this.checkResearchCode = this.checkResearchCode.bind(this);

    }


    handleCodeForCheckingChange(e) {
        this.setState({
            codeForCheckingError:""
        })
        this.setState({
            codeForChecking: e.target.value
        });
    }

    checkResearchCode(researchCode){
        this.setState({
            codeForCheckingLoader: true
        })
        checkResearchCode(researchCode).then((data) => {
            this.setState({
                codeForCheckingLoader: false
            })
            if(data.data.status){

                this.props.setResearchCode(data.data.researchCode);
                this.props.setResearchName(data.data.researchName);
                this.props.setLotReportType(data.data.lotReportType);
                this.props.setReportType(data.data.lotReportType);
                this.props.setItemsType(data.data.itemsType);
                this.props.setCustomFactors(data.data.factors);
                this.props.setResearchAdminEmail(data.data.researchAdminEmail);
                this.props.setResearchAdminName(data.data.researchAdminName);
                this.props.setResearchInviteText(data.data.inviteText);

                this.props.setUserEmail(data.data.email);
                if(data.data.reportId){
                    if(this.props.global.reportId && this.props.global.reportId != data.data.reportId){
                        unifiyReport(this.props.global.reportId,data.data.reportId).then((data) => {
                            // maybe show errors if this goes wrong
                        })
                    }else{
                        this.props.setReportId(data.data.reportId);
                    }
                }
            }else{
                this.setState({
                    codeForCheckingError: data.data.message
                })
            }
        })
    }

    render() {
        return (
            <>
                {this.props.global.researchName ?
                    <Row className={"mt-4"}>
                        <Col>
                            <h5>You are participating in the research "{this.props.global.researchName}"</h5>
                        </Col>
                    </Row>
                    :
                    [(this.state.openCodeForChecking ?
                            <Row key={"1"} className={"mt-4 check-research-code-wrap"}>
                                <Col md={4} xs={12}>
                                    <label className={"mr-4"} htmlFor="codeForChecking">Research code</label>
                                    <input className={this.state.codeForCheckingError.length > 0 ? "error" : ""}
                                           id={"codeForChecking"} name="codeForChecking" value={this.state.codeForChecking}
                                           onChange={this.handleCodeForCheckingChange}/>
                                    {this.state.codeForCheckingLoader &&
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    }
                                </Col>
                                <Col className={"mt-4 mt-md-0"} md={8} xs={12}>
                                    <button className={"action-button secondary longer"}
                                            onClick={() => this.checkResearchCode(this.state.codeForChecking)}>Login
                                    </button>

                                </Col>
                                <Col xs={12}>
                                    <div className={"code-error"}>{this.state.codeForCheckingError}</div>
                                </Col>
                            </Row>
                            :
                            <a key={"1"} onClick={(e) => {e.preventDefault();this.setState({openCodeForChecking:true});}} href={"#"}>Click here if you participate in a research study.</a>
                    )]
                }
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setRetestEmail,
        setUserEmail,
        resetState,
        setReportType,
        setResearchCode,
        setResearchName,
        setReportId,
        setLotReportType,
        setItemsType,
        setCustomFactors,
        addCustomFactor,
        setResearchAdminEmail,
        setResearchAdminName,
        setResearchInviteText
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(CheckResearch);