import React from 'react';
import {setAnswer,setBookmarkSaved} from "../actions";
import {connect} from 'react-redux'
import {Container,Row,Col} from 'react-bootstrap'
import {Radio, RadioGroup,FormControlLabel} from '@material-ui/core'
import StyledRadio from './elements/StyledRadio'
import animateScrollTo from 'animated-scroll-to';


class FormItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.labelClick = this.labelClick.bind(this)
    }

    handleChange(event){
        const target = event.target;
        this.props.setAnswer(this.props.item.nr,parseInt(target.value))
        this.props.setBookmarkSaved(false)

        // window.scroll({
        //     behavior: 'smooth',
        //     top: window.scrollY + document.getElementsByClassName('item-row')[0].offsetHeight
        // })

        animateScrollTo(window.scrollY + event.target.closest(".item-row").offsetHeight)
    }

    labelClick(e){
        e.target.parentElement.parentElement.children[1].click();
    }

    render() {
        return (
            <Row className={"item-row" + (this.props.answer != 0 ? " answered" : " unanswered")}>
                <Col sm={"12"}><div className={"item-text text-center font-weight-bold mt-5"}>{this.props.item.text}</div></Col>
                <Col sm={"12"}>
                    <RadioGroup name={"item"+this.props.number} value={this.props.answer} onChange={this.handleChange}>
                        <Row className={"row-labels"}>
                            <Col className={"d-none d-xl-block"}></Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Almost never</span> <span className={"second"}>(definitely no)</span></div>
                                <FormControlLabel value={1} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Rarely</span> <span className={"second"}>Seldom</span></div>
                                <FormControlLabel value={2} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Occasionally</span> <span className={"second"}>Sometimes</span></div>
                                <FormControlLabel value={3} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Often</span> <span className={"second"}>Regularly</span></div>
                                <FormControlLabel value={4} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Very frequently</span> <span className={"second"}>Usually</span></div>
                                <FormControlLabel value={5} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"text-center"}>
                                <div onClick={(e) => this.labelClick(e)} className={"question-label"}><span>Almost always</span> <span className={"second"}>(definitely yes)</span></div>
                                <FormControlLabel value={6} control={<StyledRadio color="primary" />} />
                            </Col>
                            <Col className={"d-none d-xl-block"}></Col>
                        </Row>
                    </RadioGroup>
                </Col>
            </Row>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = () => {
    return {
        setAnswer,
        setBookmarkSaved
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(FormItem);