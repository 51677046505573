import React from 'react';
import {connect} from "react-redux";
import {Row,Col,Spinner} from 'react-bootstrap'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import {goto} from "../actions";

class CustomizeResearch extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
       
    }

    calcTotalCustomItems(){
        var total = 0;
        if(this.props.global.customFactors){
            items.forEach(item => {

                let filteredArray = item.factors.filter(value => this.props.global.customFactors.includes(value));
                if(filteredArray.length > 0){
                    total++
                }
            })
        }
        return total;
    }

    hasFactor(factorId){

        if(!this.props.global.customFactors){
            return false;
        }

        if(this.props.global.customFactors.includes(parseInt(factorId))){
            return true;
        }

        return false;
    }

    render() { 
        return (
            <>
                <Row className={"mt-4"}>
                    <Col>
                        <h3 id={"page-title"}>{texts.customize.title}</h3>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <div className={"landing-participants-text"} dangerouslySetInnerHTML={{ __html : texts.customize.landingParticipants }}></div>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col>
                        <p>This CQ-i Assessment was pre-configured by the Research Admin / Project Manager.</p>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col>
                        <p><b>Title: </b>{this.props.global.researchName}</p>
                        <p><b>Research Admin / Project Manager: </b>{this.props.global.researchAdminName}</p>
                        <p><b>Email: </b>{this.props.global.researchAdminEmail}</p>
                    </Col>
                </Row>
                {this.props.global.researchInviteText &&
                this.props.global.researchInviteText.length > 0 &&
                <Row className={"mt-2"}>
                    <Col>
                        <p>This is their message for you:</p>
                        <p>{this.props.global.researchInviteText}</p>
                    </Col>
                </Row>
                }
                <Row className={"mt-1"}>
                    <Col>
                        <h6>Selected customization for this research/project:</h6>
                        <h6>{this.props.global.reportType == "full" ? "CQ-i Assessment Report" : "CQ-i Descriptive Summary"}</h6>
                        <ul style={{listStyleType:"none"}} className={"pl-3"}>
                        {
                            {
                            'full-full':  <li>
                                <FormControlLabel disabled control={<Checkbox checked={this.props.global.itemsType == "full"} value="full" color="primary" />} label="Complete assessment (237 items)" onChange={this.handleReportTypeChange} />
                            </li>,
                            'full-custom': <li>
                                <FormControlLabel disabled control={<Checkbox  checked={this.props.global.itemsType == "custom"} value="custom" color="primary" />} label="Scales selection" onChange={this.handleReportTypeChange} />

                                {this.props.global.itemsType == "custom" &&
                                <div>
                                    <ul className={"factorsHolder"}>
                                        {factors.map((factor, k) => {
                                            return <li key={k}><FormControlLabel
                                                control={<Checkbox disabled checked={this.hasFactor(factor.id)} color="primary" name="factors[]" value={factor.id}/>}
                                                label={factor.name + " (" + factor.itemCount + " items)"}
                                                onChange={this.handleCustomFactorChange}/></li>
                                        })}
                                    </ul>
                                    <h5 className={"mt-3"}>Total items in your selection: {this.calcTotalCustomItems()}</h5>
                                    <p>*Some CQ-i items are overlapping, they may be included in more than one scale.</p>
                                </div>
                                }
                            </li>,
                            'free-full' : <li>
                                <FormControlLabel control={<Checkbox disabled checked={true} value="free" color="primary" />} label="Complete assessment (237 items)" onChange={this.handleReportTypeChange} />
                            </li> 
                            }[this.props.global.reportType+"-"+this.props.global.itemsType]
                        }
                        </ul>
                    </Col>
                </Row>
                
                <Row className={"mb-5 mt-4"}>
                    <Col>
                        <button className={"action-button"} onClick={() => this.props.goto('demographicsStep')}>Next step <i className={"ml-3 icon-arrow-right"}></i></button>
                    </Col>
                </Row>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        goto
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(CustomizeResearch);