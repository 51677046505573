import React from 'react';
import {connect} from "react-redux";
import {DOWNLOAD_REPORT, generatePdf} from "../api";
import {setGiftPdfGenerated} from "../actions";
import {Container,Row,Col,Spinner} from 'react-bootstrap';


class DownloadFreeReport extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        generatePdf(this.props.reportId).then((data) => {
            if(data.data.status){
                this.props.setGiftPdfGenerated(true)
            }else{
                alert("pdf generation error");
            }
        })
    }

    render() {
        return (
            <div>
                <h4 className={"mt-3 mb-1"}>Thank you for taking the CQ-i.</h4>
                <h5 className={"mb-4"}>You are using the gift code: "<b>{this.props.giftCode}</b>"</h5>
                {!this.props.giftPdfGenerated ?
                    <>
                        <p>Generating your report, please wait..</p>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </>
                    :
                    <>
                        <p style={{marginBottom:"10px"}}>Please download the CQ-i Assessment Report, using the link below.</p>
                        <p>We've also sent you an e-mail with the download link to the e-mail address you provided.</p>
                        <p>Enjoy the journey!</p>
                        <a target={"_blank"} className={"button mt-3"} href={DOWNLOAD_REPORT+this.props.reportId+"/"+this.props.reportCode}>Download the CQ-i Assessment Report</a>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        giftPdfGenerated : state.global.giftPdfGenerated,
        lotReportType : state.global.lotReportType,
        reportId : state.global.reportId,
        reportCode : state.global.reportCode,
        reportType : state.global.reportType,
        giftCode : state.global.giftCode
    }
}

const mapDispatchToProps = () => {
    return {
        setGiftPdfGenerated
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(DownloadFreeReport);