import React from 'react';
import {connect} from "react-redux";
import {Row,Col,Spinner} from 'react-bootstrap'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import {setItemsType,setReportType,addCustomFactor,removeCustomFactor} from "../actions";

class CustomizeSingle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalItems: 0
        }

        this.handleReportTypeChange = this.handleReportTypeChange.bind(this)
        this.handleCustomFactorChange = this.handleCustomFactorChange.bind(this)
    }

    componentDidMount(){
        this.setState({totalItems: this.calcTotalCustomItems()});
    }

    calcTotalCustomItems(){
        var total = 0;
        if(this.props.global.customFactors){
            items.forEach(item => {

                let filteredArray = item.factors.filter(value => this.props.global.customFactors.includes(value));
                if(filteredArray.length > 0){
                    total++
                }
            })
        }
        return total;
    }

    handleReportTypeChange(event) {

        const target = event.target;
        if(target.value == "free"){
            this.props.setReportType(target.value);
            this.props.setItemsType("full");
        }else{
            this.props.setReportType("full");
            this.props.setItemsType(target.value);
        }
    }

    handleCustomFactorChange(event) {
        const target = event.target;
        const checked = event.target.checked;
        new Promise((resolve, reject) => {
            if(checked){
                this.props.addCustomFactor(target.value);
            }else{
                this.props.removeCustomFactor(target.value);
            }
            resolve();
        }).then(() => {
            this.setState({totalItems: this.calcTotalCustomItems()});

            this.setState({test: !this.state.test});
        })

    }

    hasFactor(factorId){
        if(!this.props.global.customFactors){
            return false;
        }

        if(this.props.global.customFactors.includes(parseInt(factorId))){
            return true;
        }

        return false;
    }

    render() {
        return (
            <>
                <Row className={"mt-4"}>
                    <Col>
                        <h3 id={"page-title"}>Customize your CQ-i Assessment</h3>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <p>Please customize your conscious experience assessment:</p>
                    </Col>
                </Row>
                <Row className={"mt-1"}>
                    <Col>
                        <h6>CQ-i Descriptive Summary (13 pages, free licensing for personal use)</h6>
                        <ul style={{listStyleType:"none"}} className={"pl-3"}>
                            <li>
                                <FormControlLabel control={<Checkbox checked={this.props.global.reportType == "free"} value="free" color="primary" />} label="Complete assessment (237 items)" onChange={this.handleReportTypeChange} />
                            </li> 
                        </ul>
                    </Col>
                </Row>
                <Row className={"mt-1"}> 
                    <Col>
                        <h6>CQ-i Assessment Report (22 pages, paid option - 15/50 EUR or gift code)</h6>
                        <ul style={{listStyleType:"none"}} className={"pl-3"}>
                            <li>
                                <FormControlLabel control={<Checkbox checked={this.props.global.reportType == "full" && this.props.global.itemsType == "full"} value="full" color="primary" />} label="Complete assessment (237 items)" onChange={this.handleReportTypeChange} />
                            </li>
                            <li>
                                <FormControlLabel control={<Checkbox  checked={this.props.global.itemsType == "custom"} value="custom" color="primary" />} label="Scales selection" onChange={this.handleReportTypeChange} />

                                {this.props.global.itemsType == "custom" &&
                                <div>
                                    <ul className={"factorsHolder"}>
                                        {factors.map((factor, k) => {
                                            return <li key={k}><FormControlLabel
                                                control={<Checkbox checked={this.hasFactor(factor.id)} color="primary" name="factors[]" value={factor.id}/>}
                                                label={factor.name + " (" + factor.itemCount + " items)"}
                                                onChange={this.handleCustomFactorChange}/></li>
                                        })}
                                    </ul>
                                    <h5 className={"mt-3"}>Total items in your selection: {this.state.totalItems}</h5>
                                    <p>*Some CQ-i items are overlapping, they may be included in more than one scale.</p>
                                </div>
                                }
                            </li> 
                        </ul>
                    </Col>
                </Row>
                
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        addCustomFactor,
        removeCustomFactor,
        setItemsType,
        setReportType
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(CustomizeSingle);