import React from 'react';
import Moment from 'react-moment';
import {confirmIntent, continueReport, createIntent, DOWNLOAD_REPORT} from '../../api';
import {connect} from 'react-redux'
import {Container,Row,Col,Spinner} from 'react-bootstrap';
import {Radio, RadioGroup,FormControlLabel} from '@material-ui/core'
import PaymentForm from '../PaymentForm'
import {loadStripe} from '@stripe/stripe-js';
import moment from 'moment';
 
import {
    setAllAnswers,
    setUserEmail,
    setUserData,
    setResearchCode,
    setResearchName,
    setLotReportType,
    setReportId,
    setReportStatus,
    setReportCode,
    setAllUserData,
    goto
} from '../../actions';


const stripePromise = loadStripe(stripePublicKey);

class PreviousReportItem extends React.PureComponent {

    constructor(props) {

        super(props);

        this.continueAssessment = this.continueAssessment.bind(this)
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this)
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this)
        this.hidePaymentForm = this.hidePaymentForm.bind(this)
        this.proceed = this.proceed.bind(this)

        this.state = {
            showPaymentForm: false,
            clientSecret: false,
            chooseType: false,
            reportType: "full",
            createIntentLoading: false,
            paymentSuccess: false
        }
    }

    continueAssessment(reportId,reportCode){
        continueReport(reportId,reportCode).then((data) => {
            this.props.setAllAnswers(data.data.report.answers)
            this.props.setUserEmail(data.data.report.email)
            this.props.setAllUserData(data.data.report.demografice)
            this.props.setReportCode(data.data.report.code)
            this.props.setReportId(data.data.report.id)
            this.props.setReportStatus(data.data.report.status)
            if(data.data.report.researchLot){
                this.props.setResearchCode(data.data.report.researchLot.code)
                this.props.setLotReportType(data.data.report.researchLot.reportsType)
                this.props.setResearchName(data.data.report.researchLot.research.name)
            }
            this.props.goto("stepQuestions")
        })
    }

    handleReportTypeChange(e){
        this.setState({
            reportType: e.target.value
        })
    }

    handlePaymentSuccess(){
        return confirmIntent(this.props.report.id).then((data) => {
            return data.data.status;
        });
    }

    hidePaymentForm(){
        this.props.report.isEligibleForFull = true;
        this.setState({
            showPaymentForm: false,
            paymentSuccess: true
        })
    }

    proceed(){
        if(!this.state.reportType){
            alert("Please select the licensing option");
        }else{

            this.setState({
                createIntentLoading:true,
                chooseType:false
            })
            createIntent(this.props.report.id,this.state.reportType).then((data) => {
                if(data.data.status){
                    this.setState({
                        clientSecret: data.data.clientSecret,
                        showPaymentForm: true,
                        createIntentLoading: false,
                        amount: data.data.amount
                    });
                }else if(data.data.isPaid){
                    this.props.report.isEligibleForFull = true;
                    this.setState({
                        createIntentLoading: false
                    });
                }

            })

        }
    }

    render() {

        var now = moment(new Date()); //todays date
        var last = moment(this.props.report.created); // another date
        var duration = moment.duration(now.diff(last));
        var months = duration.asMonths();

        return (
            <li className={"mb-3 prev-item"}>
                
                <span className={"mr-3"}>
                    Assessment made on <Moment format="YYYY/MM/DD">{this.props.report.created}</Moment>
                </span>

                {this.props.report.status == "unfinished" &&
                    <>
                    { months <= 3 ?
                        <button className={"action-button secondary auto"} onClick={() => this.continueAssessment(this.props.report.id,this.props.report.code)}>Continue</button>
                        :
                        <div>More than 3 months have passed since you started the test, and your data have expired. <a class="border-bottom" href="/">Please start again.</a></div>
                    }
                    </>
                }
                
                {this.props.report.status == "finished" &&
                <>
                    {!this.props.report.isEligibleForFull ?
                        <>
                            <a target={"_blank"} className={"action-button mt-3 secondary auto button"}
                               href={DOWNLOAD_REPORT + this.props.report.id + "/" + this.props.report.code}>Download
                                CQ-i Descriptive Summary</a>
                            <button className={"action-button secondary auto mt-3 mt-sm-0 ml-0 ml-sm-3"}
                                    onClick={() => this.setState({chooseType: true})}>Upgrade to CQ-i Assessment Report
                            </button>
                        </>
                        :
                        <>
                            <a target={"_blank"} className={"action-button mt-3 secondary auto button"}
                               href={DOWNLOAD_REPORT + this.props.report.id + "/" + this.props.report.code}>Download
                                CQ-i Assessment Report</a>
                        </>
                    }
                </>
                }
                {this.state.createIntentLoading &&
                <Row className={"mt-3"}>
                    <Col md={7} className={"text-center"}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
                }
                {(this.state.chooseType || this.state.showPaymentForm) &&
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.setState({showPaymentForm:false,chooseType:false,clientSecret:null})}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>
                }
                {this.state.chooseType &&
                <>
                    <Row className={"mt-3"}>
                        <Col md={7}>
                            <h5 className={""}>Please select a licensing option:</h5>
                        </Col>
                    </Row>
                    <RadioGroup name={"reportType"} value={this.state.reportType}
                                onChange={this.handleReportTypeChange}>
                        <Row className={"mt-3"}>
                            <Col>
                                <h6>CQ‐i Assessment Report (22 pages)</h6>
                            </Col>
                        </Row>
                        <Row className={"mt-1"}>
                            <Col className={""}><FormControlLabel value={"full"}
                                                                  control={<Radio color="primary"/>}
                                                                  label={"50 EUR Fair value, supporting the CQ‐i research and the free/accessibility options. Thank you"}/></Col>
                        </Row>
                        <Row className={"mt-1"}>
                            <Col className={""}><FormControlLabel value={"tight"}
                                                                  control={<Radio color="primary"/>}
                                                                  label={"15 EUR Accessibility option for tight finances"}/></Col>
                        </Row>
                    </RadioGroup>
                    <Row className={"mt-4"}>
                        <Col>
                            <button
                                onClick={this.proceed}>Proceed to checkout</button>
                        </Col>
                    </Row>
                </>
                }
                {this.state.clientSecret && this.state.showPaymentForm &&
                <>
                    <Row>
                        <Col className={"mt-3"} md={6}>
                            <Row className={"mt-3"}>
                                <Col>
                                    <h3 className={"text-center mb-3"}>CQ-i Licensing Fee Payment</h3>
                                </Col>
                            </Row>
                            <PaymentForm
                                amount={this.state.amount}
                                handlePaymentSuccess={this.handlePaymentSuccess}
                                hidePaymentForm={this.hidePaymentForm}
                                clientSecret={this.state.clientSecret}
                                stripe={stripePromise}
                            />
                        </Col>
                    </Row>
                </>
                }
                {this.state.paymentSuccess &&
                <h4 className={"mt-2"}>Your payment was successful. Thank you.</h4>
                }
            </li>
        )
    }
}


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = () => {
    return {
        setAllAnswers,
        setUserEmail,
        setResearchCode,
        setResearchName,
        setLotReportType,
        setReportId,
        setReportStatus,
        setReportCode,
        setAllUserData,
        goto
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(PreviousReportItem);


