import React from 'react';
import Radio from '@material-ui/core/Radio';

export default function StyledRadio(props) {
    return (
        <Radio
            checkedIcon={<span className={"checkedIcon"} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}
