import React from 'react';
import {Container,Row,Col,Spinner} from 'react-bootstrap'
import {connect} from "react-redux";
import {goto,setReportType,setGiftCode,setReportStatus} from "../actions";
import {Radio, RadioGroup,FormControlLabel} from '@material-ui/core'
import {saveReport,createIntent,confirmIntent,applyGiftCode} from "../api";
import PaymentForm from "./PaymentForm";
import CheckResearch from "./CheckResearch";
import {loadStripe} from '@stripe/stripe-js';
import DownloadFullReport from './DownloadFullReport';
import DownloadFreeReport from './DownloadFreeReport';
import DownloadGiftReport from './DownloadGiftReport';
import {pausePersist} from "../index"

const stripePromise = loadStripe(stripePublicKey);

class FormFinalStep extends React.Component {

    constructor(props) {
        super(props);
        this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
        this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);
        this.hidePaymentForm = this.hidePaymentForm.bind(this);
        this.hideFreeDownload = this.hideFreeDownload.bind(this);
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this);
        this.proceed = this.proceed.bind(this);
        this.applyTheGiftCode = this.applyTheGiftCode.bind(this);
        this.state = {
            reportStatusLoading: true,
            clientSecret: null,
            showPaymentForm: false,
            chooseType: true,
            showFullDownload:false,
            showFreeDownload:false,
            showGiftDownload:false,
            createIntentLoading: false,
            giftCheckLoading: false,
            amount:null,
            showText: true,
            giftCodeError: false
        }
    }

    proceed(){
        if(!this.props.global.reportType){
            alert("Please select the licensing option");
        }else{

            if(this.props.global.reportType == "free"){
                this.setState({
                    chooseType:false,
                    showFreeDownload: true,
                    showText: false
                })
                //pausePersist();
            }else{

                this.setState({
                    createIntentLoading:true,
                    chooseType:false,
                    showText: false
                })

                createIntent(this.props.global.reportId,this.props.global.reportType).then((data) => {
                    if(data.data.status){
                        this.setState({
                            clientSecret: data.data.clientSecret,
                            showPaymentForm: true,
                            createIntentLoading: false,
                            amount: data.data.amount
                        });
                    }else if(data.data.isPaid){
                        this.setState({
                            createIntentLoading: false,
                            showFullDownload:true
                        });
                        //pausePersist();
                    }

                })

            }

        }
    }

    hidePaymentForm(){
        this.setState({
            showPaymentForm: false,
            showFullDownload: true,
        })
        //pausePersist();
    }

    handlePaymentSuccess(){

        return confirmIntent(this.props.reportId).then((data) => {
            return data.data.status;
        });
    }

    hideFreeDownload(){
        this.setState({
            chooseType:true,
            showFreeDownload: false,
            showText: true
        })
    }

    applyTheGiftCode(){
        this.setState({
            giftCheckLoading: true
        })
        return applyGiftCode(this.props.reportId,this.props.giftCode).then((data) => {
            this.setState({
                giftCheckLoading: false
            })
            if(data.data.status){
                this.setState({
                    giftCodeError: false,
                    showGiftDownload: true,
                    chooseType:false,
                    showText: false
                })
            }else{
                this.setState({
                    giftCodeError: true
                })
            }
        });
    }


    handleGiftCodeChange(e){
        this.props.setGiftCode(e.target.value)
        this.setState({
            giftCodeError: false
        })
    }
    handleReportTypeChange(e){
        this.props.setReportType(e.target.value)
    }

    componentDidMount(){

        setTimeout(() => {
            window.scrollTo(0, document.getElementById("page-title").offsetTop-300)
        },100)

        new Promise(resolve => {
            this.props.setReportStatus("finished");
            if(this.props.global.itemsType == "free"){
                this.props.setReportType("free")
            }
            resolve();
        }).then(() => saveReport(this.props.state)).then(() => {
            this.setState({reportStatusLoading:false})
        })
    }

    render() {
        return (
            <>
                {this.state.showText &&
                <>
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.props.goto('stepQuestions')}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <h3 id={"page-title"}>{texts.final.title}</h3>
                    </Col>
                </Row>

                <Row className={"mt-3"}>
                    <Col md={7}>
                        <div className={"final-text"} dangerouslySetInnerHTML={{__html: texts.final.text}}></div>
                    </Col>
                </Row>
                </>
                }

                {!this.state.reportStatusLoading ?
                <>
                    {!this.props.global.lotReportType && this.state.chooseType &&
                        <>
                            <Row className={""}>
                                <Col md={7}>
                                    <p className={""}>Please select your licensing option:</p>
                                </Col>
                            </Row>
                            <RadioGroup name={"reportType"} value={this.props.global.reportType}
                                        onChange={this.handleReportTypeChange}>
                                <Row className={""}>
                                    <Col>
                                        <div className={"font-weight-bold"}>CQ-i Descriptive Summary (13 pages)</div>
                                    </Col>
                                </Row>
                                <Row className={"mt-1"}>
                                    <Col className={""}><FormControlLabel value={"free"}
                                                                          control={<Radio color="primary"/>}
                                                                          label={"Free licensing for personal use"}/></Col>
                                </Row>
                                <Row className={""}>
                                    <Col>
                                        <div className={"font-weight-bold"}>CQ‐i Assessment Report (22 pages)</div>
                                    </Col>
                                </Row>
                                <Row className={"mt-1"}>
                                    <Col className={""}><FormControlLabel value={"full"}
                                                                          control={<Radio color="primary"/>}
                                                                          label={"50 EUR Fair value, supporting the CQ-i research and the free/accessibility options. Thank you."}/></Col>
                                </Row>
                                <Row className={"mt-1"}>
                                    <Col className={""}><FormControlLabel value={"tight"}
                                                                          control={<Radio color="primary"/>}
                                                                          label={"15 EUR Accessibility option for tight finances"}/></Col>
                                </Row>
                                <Row className={"mt-1 mb-1"}>
                                    <Col>
                                        <label htmlFor={"giftCode"}>If you received a gift code, apply it here </label><input
                                        name={"giftCode"} id={"giftCode"} value={this.props.global.giftCode}
                                        onChange={this.handleGiftCodeChange}/>{this.props.global.giftCode &&
                                    <button className={"ml-4"} onClick={() => this.applyTheGiftCode()}>Apply</button>}
                                        {this.state.giftCodeError &&
                                            <div style={{color:"#5000a0"}} className={"mt-3 d-block"}>The gift code you entered is not valid.</div>
                                        }

                                    </Col>

                                </Row>
                                {this.state.giftCheckLoading && 
                                <Row className={"mt-3"}>
                                    <Col md={7} className={"text-center"}>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </Col>
                                </Row>
                                }
                                
                            </RadioGroup>
                             
                            <Row className={"mt-4"}>
                                <Col>
                                    <button
                                        onClick={this.proceed}>Proceed {this.props.global.reportType == "free" ? " to download" : ""} {this.props.global.reportType == "full" || this.props.global.reportType == "tight" ? " to checkout" : ""}</button>
                                </Col>
                            </Row>
                        </>
                    }
                    {this.state.createIntentLoading &&
                        <Row className={"mt-3"}>
                            <Col md={7} className={"text-center"}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </Col>
                        </Row>
                    }
                    {this.state.clientSecret && this.state.showPaymentForm &&
                    <>
                        <Row className={"row mt-3 mb-3"}>
                            <Col>
                                <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.setState({showText:true,showPaymentForm:false,chooseType:true,clientSecret:null})}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Row className={"mt-1 mb-3"}>
                                    <Col>
                                        <h3 className={"text-center"}>CQ-i Licensing Fee Payment</h3>
                                    </Col>
                                </Row>
                                <PaymentForm
                                    amount={this.state.amount}
                                    handlePaymentSuccess={this.handlePaymentSuccess}
                                    hidePaymentForm={this.hidePaymentForm}
                                    clientSecret={this.state.clientSecret}
                                    stripe={stripePromise}
                                />
                            </Col>
                        </Row>
                    </>
                    }
                    {(this.state.showFullDownload || this.props.global.lotReportType == "full") &&
                    <Row>
                        <Col md={6}>
                            <DownloadFullReport />
                        </Col>
                    </Row>
                    }
                    {(this.state.showFreeDownload || this.props.global.lotReportType == "free") &&
                    <Row>
                        <Col md={6}>
                            <DownloadFreeReport hideFreeDownload={this.hideFreeDownload} />
                        </Col>
                    </Row>
                    }
                    {(this.state.showGiftDownload) &&
                    <Row>
                        <Col md={6}>
                            <DownloadGiftReport />
                        </Col>
                    </Row>
                    }

                </>
                :
                <>
                    <Row className={"mt-3"}>
                        <Col md={7} className={"text-center"}>
                           <Spinner animation="border" role="status">
                               <span className="sr-only">Loading...</span>
                           </Spinner>
                        </Col>
                    </Row>
                </>
                }


            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        step : state.step,
        global: state.global,
        state: state,
        reportId : state.global.reportId,
        giftCode : state.global.giftCode
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setReportType,
        setGiftCode,
        setReportStatus
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(FormFinalStep);
