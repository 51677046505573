/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

import App from './components/App.js';
import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux'
import rootReducer from './reducers'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
    key: 'global',
    storage,
    stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    //persistedReducer,
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)

export const pausePersist = () => {
    persistor.pause();
    persistor.purge();
}

try {
    ReactDOM.render(
        <Provider store={store}>
            {/*<PersistGate loading={null} persistor={persistor}>*/}
                <App/>
            {/*</PersistGate>*/}
        </Provider>,
        document.getElementById('app')
    );
} catch (error) {
    console.error(error);
}

