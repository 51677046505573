import React from 'react';
import {goto,setUserData,setReportId,setReportCode} from "../actions";
import {saveReport} from "../api";
import {connect} from 'react-redux'
import {Container,Row,Col} from 'react-bootstrap'

class InstructionsStep extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        setTimeout(() => {
            window.scrollTo(0, document.getElementById("page-title").offsetTop-300)
        },100)

        // saveReport(this.props.state).then((data) => {
        //     this.props.setReportId(data.data.reportId)
        //     this.props.setReportCode(data.data.reportCode)
        // })
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {e.preventDefault();this.props.goto('demographicsStep')}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col>
                        <h3 id={"page-title"}>Test instructions</h3>
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col md={7}>
                        <h5 className={"text-center"}>Please read the following instructions carefully</h5>
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col md={7}>
                        <p className={"instruction-first-p"}>{texts.instructions.firstP}</p>
                    </Col>
                </Row>
                <Row className={"mt-4 mb-4"}>
                    <Col md={7}>
                        <table className={"instruction-table"}>
                            <tbody>
                                {texts.instructions.table.map((tr, k) => <tr key={k}><td>{tr.label}</td><td>{tr.value}</td></tr>)}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className={""}>
                    <Col md={7}>
                        <div className={"instructions-text"} dangerouslySetInnerHTML={{ __html : texts.instructions.text }}></div>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col>
                        <h3>Enjoy the journey!</h3>
                    </Col>
                </Row>
                <Row className={"mb-5 mt-4"}>
                    <Col>
                        <button className={"action-button auto"} onClick={() => this.props.goto('stepQuestions')}>Begin assessment <i className={"ml-3 icon-arrow-right"}></i></button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        step : state.step,
        userData: state.userData,
        state: state
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setUserData,
        setReportId,
        setReportCode
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(InstructionsStep);