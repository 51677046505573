import React from 'react';
import {goto,setUserData,setResearchCode,setPrivacyAccepted} from "../actions";
import {connect} from 'react-redux'
import {validateEmail} from '../utils'
import {Checkbox, FormControlLabel} from '@material-ui/core'
import {Container,Row,Col} from 'react-bootstrap'

class DemographicsStep extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nameError:"",
            privacyAccepted: false,
            emailError:"",
            selectErrors:{
                age: false,
                country: false,
                gender: false,
                education: false,
                income: false
            }
        }

        this.userFormRef = React.createRef()

        this.handleInputChange = this.handleInputChange.bind(this)
        this.acceptTerms = this.acceptTerms.bind(this)
        this.next = this.next.bind(this)
        this.ages = [];
        for(let i=16; i<=100; i++){
            this.ages.push(i);
        }
        this.genders = ["Female","Male","Transgender/Other"];
        this.countries = countries;
        this.educations = ["No formal education","Home-schooling","Primary School","Secondary/High School","College/University or equivalent","Master’s degree or equivalent","Ph.D./Doctoral degree"];
        this.incomes = ["Below 200","200‐500","501‐1000","1001‐2000","2000‐3000","3000‐5000","More than 5000"];
    }

    handleInputChange(event) {

        const target = event.target;

        if(target.name == "name"){
            this.setState({
                nameError: ""
            })
        }
        if(target.name == "email"){
            this.setState({
                emailError: ""
            })
        }

        if(typeof this.state.selectErrors[target.name] !== "undefined"){
            this.setState({
                selectErrors:{
                    ...this.state.selectErrors,
                    [target.name]: false
                }
            })
        }


        this.props.setUserData(target.name,target.value);
    }

    next(){
        let err = false;

        if(this.props.userData.name.length == 0){
            err = true;
            this.setState({
                nameError: "Please enter your name"
            })
        }

        if(!validateEmail(this.props.userData.email)){
            err = true;
            this.setState({
                emailError: "Please enter a valid email address"
            })
        }
        let selectErrors = this.state.selectErrors;
        [].slice.call(document.querySelectorAll("select.valid-input")).map((el) =>{
            if(this.props.userData[el.getAttribute("name")] == ""){
                err = true;
                selectErrors[el.getAttribute("name")] = true
                console.log("err",el.getAttribute("name"))
            }
        })

        this.setState({
            selectErrors: selectErrors
        })


        if(!err){
            this.props.goto('instructionsStep')
        }else{
            window.scrollTo(0, this.userFormRef.current.offsetTop)
        }
    }

    componentDidMount(){
        [].slice.call(document.getElementsByClassName("init-input")).map((el) => {
          el.dispatchEvent(new Event('change', { bubbles: true}));
        })
        setTimeout(() => {
            window.scrollTo(0, this.userFormRef.current.offsetTop-300)
        },100)

    }

    goBack(e){
        e.preventDefault();
        this.props.goto('step1');
    }

    acceptTerms(){
        this.setState({
            privacyAccepted: this.state.privacyAccepted ? false : true
        }, function(){
            if(this.state.privacyAccepted){
                this.props.setPrivacyAccepted(Date.now());
            }else{
                this.props.setPrivacyAccepted(null);
            }
        })
        
        
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <a className={"back-button border-bottom border-dark"} href={"#"} onClick={(e) => {this.goBack(e)}}><i className={"mr-2 icon-arrow-left"}></i>Back</a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className={"mt-3 mb-3"}>Socio-demographics</h3>
                    </Col>
                </Row>
                <div ref={this.userFormRef} className={"userDataForm"}>
                    <Row className={"mb-3"}>
                        <Col md={4}>
                            <label htmlFor={"name"}>Name</label>
                            <input className={"d-block init-input"} type={"text"} name={"name"} value={this.props.userData.name} onChange={this.handleInputChange}/>
                            {this.state.nameError &&
                            <label className={"error"} htmlFor={"name"}>{this.state.nameError}</label>
                            }
                        </Col>
                        <Col md={4}>
                            <label htmlFor={"email"}>Email</label>
                            <input className={"d-block init-input"} type={"text"} name={"email"} value={this.props.userData.email} onChange={this.handleInputChange}/>
                            {this.state.emailError &&
                            <label className={"error"} htmlFor={"email"}>{this.state.emailError}</label>
                            }
                        </Col>
                        <Col md={4}>
                            <label htmlFor={"age"}>Age</label>
                            <select value={this.props.userData.age} name={"age"} className={"d-block w-100 init-input valid-input"+(this.state.selectErrors.age ? " err" : "")} onChange={this.handleInputChange}>
                                <option value={""}> -- Please select -- </option>
                                {this.ages.map((age) =>  <option key={age} value={age}>{age}</option>)}
                            </select>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col md={4}>
                            <label htmlFor={"gender"}>Sex</label>
                            <select value={this.props.userData.gender} name={"gender"} className={"d-block w-100 init-input valid-input"+(this.state.selectErrors.gender ? " err" : "")} onChange={this.handleInputChange}>
                                <option value={""}> -- Please select -- </option>
                                {this.genders.map((gender) =>  <option key={gender} value={gender}>{gender}</option>)}
                            </select>
                        </Col>
                        <Col md={4}>
                            <label htmlFor={"country"}>Country</label>
                            <select value={this.props.userData.country} name={"country"} className={"d-block w-100 init-input valid-input"+(this.state.selectErrors.country ? " err" : "")} onChange={this.handleInputChange}>
                                <option value={""}> -- Please select -- </option>
                                {Object.keys(this.countries).map((code) =>  <option key={code} value={this.countries[code]}>{this.countries[code]}</option>)}
                            </select>
                        </Col>
                        <Col md={4}>
                            <label htmlFor={"education"}>Highest degree of formal education</label>
                            <select value={this.props.userData.education} name={"education"} className={"d-block w-100 init-input valid-input"+(this.state.selectErrors.education ? " err" : "")} onChange={this.handleInputChange}>
                                <option value={""}> -- Please select -- </option>
                                {this.educations.map((edu) =>  <option key={edu} value={edu}>{edu}</option>)}
                            </select>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col md={8}>
                            <h5>Confidentiality and privacy</h5>
                            <div className={"some-info"} dangerouslySetInnerHTML={{ __html : texts.disclaimer }}></div>
                        </Col>
                    </Row>
                    <Row className={"mb-3"}>
                        <Col md={8}>
                            <FormControlLabel control={<Checkbox checked={this.state.privacyAccepted} value="true" color="primary" />} label="Yes, I agree" onChange={this.acceptTerms} />
                        </Col>
                    </Row>
                    <Row className={"mb-5 mt-3"}>
                        <Col>
                            <button className={"action-button"} disabled={!this.state.privacyAccepted} onClick={() => this.next()}>Next step <i className={"icon-arrow-right"}></i></button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        step : state.step,
        userData: state.userData,
        global: state.global
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        setUserData,
        setResearchCode,
        setPrivacyAccepted
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(DemographicsStep);