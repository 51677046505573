import React from 'react';
import { connect } from 'react-redux'
import { goto } from '../actions'
import FormStep1 from './FormStep1'
import DemographicsStep from './DemographicsStep'
import InstructionsStep from './InstructionsStep'
import CustomizeStep from './CustomizeStep'
import FormQuestions from './FormQuestions'
import FormFinalStep from './FormFinalStep'
import {Container,Row,Col} from 'react-bootstrap'
import {resetState} from "../actions";

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    resetApp(e){
        e.preventDefault();
        this.props.resetState();
    }

    render() {
        return (
            <div className="App">
                <Container className={"pb-5"}>
                    {this.props.step != "step1" &&
                    <div className={"position-relative"}>
                        <a href={"#"} className={"float-right reset-button"} onClick={(e) => this.resetApp(e)}>Reset/restart from the beginning</a>
                    </div>
                    }
                    {this.props.step == "step1" && <FormStep1 />}
                    {this.props.step == "customizeStep" && <CustomizeStep />}
                    {this.props.step == "demographicsStep" && <DemographicsStep />}
                    {this.props.step == "instructionsStep" && <InstructionsStep />}
                    {this.props.step == "stepQuestions" && <FormQuestions />}
                    {this.props.step == "stepFinal" && <FormFinalStep />}
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        step : state.step
    }
}

const mapDispatchToProps = () => {
    return {
        goto,
        resetState
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(App);